// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import dashboard from '@src/views/dashboard/store'
import user from '@src/views/users/store'
import division from '@src/views/divisions/store'
import job from '@src/views/jobs/store'
import report from '@src/views/reports/store'
import monitoring from '@src/views/monitoring/store'
import classification from '@src/views/classifications/store'
import tag from '@src/views/tags/store'
import disposisi from '@src/views/disposisi/store'

const rootReducer = {
  auth,
  dashboard,
  division,
  job,
  report,
  monitoring,
  classification,
  tag,
  disposisi,
  user,
  navbar,
  layout
}

export default rootReducer
