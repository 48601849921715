// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

// ** Axios Imports
import axios from 'axios'

export const getAll = createAsyncThunk('reports/getAll', async (tab) => {
    const response = await axios.get(`/reports/get/${tab}`)
    return response.data
})

export const getReport = createAsyncThunk('reports/getReport', async (id) => {
  const response = await axios.get(`/reports/${id}`)
  return response.data
})

export const getDivision = createAsyncThunk('reports/getDivision', async (id) => {
  const response = await axios.get(`/jobs/division/${id}`)
  return response.data
})

export const getFile = createAsyncThunk('reports/getFile', async (file) => {
  const response = await axios.post(`/reports/file`, file, { responseType: 'blob' })
  const fileData = response.data
  // Buat objek URL untuk file
  const fileUrl = URL.createObjectURL(fileData)
  return fileUrl
})

export const getAllJobs = createAsyncThunk('reports/getAllJobs', async () => {
  const response = await axios.get('/jobs/2')
  return response.data
})

export const addData = createAsyncThunk(
    'reports/addData',
    async (report, { dispatch }) => {
        const response = await axios.post('/reports', report)
        await dispatch(getAll('todo'))
        return response.data
    }
)

// export const updateData = createAsyncThunk(
//     'reports/updateData',
//     async (report, { dispatch }) => {
//         const response = await axios.put(`/reports/${report.id}`, report)
//         await dispatch(getAll())
//         return response.data
//     }
// )

export const deleteData = createAsyncThunk(
    'reports/deleteData',
    async ({ id }, { dispatch }) => {
        const response = await axios.delete(`/reports/${id}`)
        await dispatch(getAll('todo'))
        return response.data
    })


export const dataSlice = createSlice({
    name: 'reports',
    initialState: {
        results: [],
        result: {},
        selected: null,
        file: null,
        division_name: '',
        histories: [],
        jobOptions: [],
        isLoading: false
    },
    reducers: {
      selectData: (state, action) => {
            if (action.payload === null) {
                state.selected = null
            } else {
                state.selected = action.payload
            }
        }
    },
    extraReducers: (builder) => {
      builder
        .addCase(getAll.pending, (state) => {
          state.isLoading = true
        })
        .addCase(getAll.fulfilled, (state, action)  => {
          state.isLoading = false
          state.results = action.payload
        })
        .addCase(getReport.pending, (state) => {
          state.isLoading = true
        })
        .addCase(getReport.fulfilled, (state, action)  => {
          state.isLoading = false
          state.result = action.payload.data
          state.histories = action.payload.histories
        })
        .addCase(getFile.pending, (state) => {
          state.file = null
          state.isLoading = true
        })
        .addCase(getFile.fulfilled, (state, action)  => {
          state.isLoading = false
          state.file = action.payload
        })
        .addCase(addData.pending, (state) => {
          state.isLoading = true
        })
        .addCase(addData.rejected, (state) => {
          state.isLoading = false
          MySwal.fire({
            title: 'Failed!',
            text: 'Mengirim laporan gagal',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          })
        })
        .addCase(addData.fulfilled, (state, action) => {
          state.isLoading = false
          MySwal.fire({
            title: 'Success!',
            html: `Mengirim laporan berhasil.<br>Nomor Laporan <b>${action.payload.code}<b>`,
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          })
        })
        // .addCase(updateData.rejected, () => {
        //   MySwal.fire({
        //     title: 'Failed!',
        //     text: 'Ubah data gagal',
        //     icon: 'error',
        //     customClass: {
        //       confirmButton: 'btn btn-primary'
        //     },
        //     buttonsStyling: false
        //   })
        // })
        // .addCase(updateData.fulfilled, () => {
        //   MySwal.fire({
        //     title: 'Success!',
        //     text: 'Ubah data berhasil',
        //     icon: 'success',
        //     customClass: {
        //       confirmButton: 'btn btn-primary'
        //     },
        //     buttonsStyling: false
        //   })
        // })
        .addCase(deleteData.rejected, () => {
          MySwal.fire({
            title: 'Failed!',
            text: 'Hapus data gagal',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          })
        })
        .addCase(deleteData.fulfilled, () => {
          MySwal.fire({
            title: 'Success!',
            text: 'Hapus data berhasil',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          })
        })
        .addCase(getAllJobs.pending, (state) => {
          state.isLoading = true
        })
        .addCase(getAllJobs.fulfilled, (state, action)  => {
          state.isLoading = false
          state.jobOptions = []
          action.payload.map(item => {
            state.jobOptions.push({ value: item.id, label: item.name })
          })
        })
        .addCase(getDivision.pending, (state) => {
          state.isLoading = true
        })
        .addCase(getDivision.fulfilled, (state, action)  => {
          state.isLoading = false
          state.division_name = action.payload.division
        })
    }
})
export const { selectData } = dataSlice.actions
export default dataSlice.reducer
