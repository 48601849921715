// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getReports = createAsyncThunk('dashboard/getReports', async () => {
  const response = await axios.get('/dashboard/reports') //ganti dg api yg filter tasks berdasarkan assignTo user_id, sort dengan dueDate ASC
  return response.data
})

export const getStatisticsAll = createAsyncThunk('dashboard/getStatisticsAll', async () => {
  const response = await axios.get('/dashboard/statistics/all')
  return response.data
})

export const getStatisticsIn = createAsyncThunk('dashboard/getStatisticsIn', async () => {
  const response = await axios.get('/dashboard/statistics/ingoing')
  return response.data
})

export const getStatisticsOut = createAsyncThunk('dashboard/getStatisticsOut', async () => {
  const response = await axios.get('/dashboard/statistics/outgoing')
  return response.data
})

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    tasks: [],
    statistics: [],
    ingoing: [],
    outgoing: [],
    targets: [],
    selected: null,
    isLoading: false,
    showTarget: false
  },
  extraReducers: {
    [getReports.pending]: (state) => {
      state.isLoading = true
    },
    [getReports.fulfilled]: (state, action) => {
      state.isLoading = false
      state.tasks = action.payload
    },
    [getStatisticsAll.pending]: (state) => {
      state.isLoading = true
    },
    [getStatisticsAll.fulfilled]: (state, action) => {
      state.isLoading = false
      state.statistics = action.payload
    },
    [getStatisticsIn.pending]: (state) => {
      state.isLoading = true
    },
    [getStatisticsIn.fulfilled]: (state, action) => {
      state.isLoading = false
      state.ingoing = action.payload
      console.log(state.ingoing)

    },
    [getStatisticsOut.pending]: (state) => {
      state.isLoading = true
    },
    [getStatisticsOut.fulfilled]: (state, action) => {
      state.isLoading = false
      state.outgoing = action.payload
    }
  }
})
export default dashboardSlice.reducer
