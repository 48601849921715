// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

// ** Axios Imports
import axios from 'axios'

export const getAll = createAsyncThunk('divisions/getAll', async () => {
    const response = await axios.get('/divisions')
    return response.data
})

export const addData = createAsyncThunk(
    'divisions/addData',
    async (division, { dispatch }) => {
        const response = await axios.post('/divisions', division)
        await dispatch(getAll())
        return response.data
    }
)

export const getAllRoles = createAsyncThunk('divisions/getAllRoles', async () => {
  const response = await axios.get('/users/roles')
  return response.data
})

export const updateData = createAsyncThunk(
    'divisions/updateData',
    async (division, { dispatch }) => {
        const response = await axios.put(`/divisions/${division.id}`, division)
        await dispatch(getAll())
        return response.data
    }
)

export const deleteData = createAsyncThunk(
    'divisions/deleteData',
    async ({ id }, { dispatch }) => {
        const response = await axios.delete(`/divisions/${id}`)
        await dispatch(getAll())
        return response.data
    })


export const dataSlice = createSlice({
    name: 'divisions',
    initialState: {
        results: [],
        result: null,
        selected: null,
        roleOptions: [],
        isLoading: false
    },
    reducers: {
      selectData: (state, action) => {
            if (action.payload === null) {
                state.selected = null
            } else {
                state.selected = action.payload
            }
        }
    },
    extraReducers: (builder) => {
      builder
        .addCase(getAll.pending, (state) => {
          state.isLoading = true
        })
        .addCase(getAll.fulfilled, (state, action)  => {
          state.isLoading = false
          state.results = action.payload
        })
        .addCase(addData.rejected, () => {
          MySwal.fire({
            title: 'Failed!',
            text: 'Tambah data gagal',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          })
        })
        .addCase(addData.fulfilled, () => {
          MySwal.fire({
            title: 'Success!',
            text: 'Tambah data berhasil',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          })
        })
        .addCase(updateData.rejected, () => {
          MySwal.fire({
            title: 'Failed!',
            text: 'Ubah data gagal',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          })
        })
        .addCase(updateData.fulfilled, () => {
          MySwal.fire({
            title: 'Success!',
            text: 'Ubah data berhasil',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          })
        })
        .addCase(deleteData.rejected, () => {
          MySwal.fire({
            title: 'Failed!',
            text: 'Hapus data gagal',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          })
        })
        .addCase(deleteData.fulfilled, () => {
          MySwal.fire({
            title: 'Success!',
            text: 'Hapus data berhasil',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          })
        })
        .addCase(getAllRoles.pending, (state) => {
          state.isLoading = true
        })
        .addCase(getAllRoles.fulfilled, (state, action)  => {
          state.isLoading = false
          state.roleOptions = []
          action.payload.map(item => {
            state.roleOptions.push({ value: item.id, label: item.title })
          })
        })
    }
})
export const { selectData } = dataSlice.actions
export default dataSlice.reducer
