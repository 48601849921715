// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

// ** Axios Imports
import axios from 'axios'

export const getAll = createAsyncThunk('monitoring/getAll', async (tab) => {
  const response = await axios.get(`/reports/monitoring/${tab}`)
  return response.data
})

export const getAllStatus = createAsyncThunk('monitoring/getAllStatus', async () => {
  const response = await axios.get('/statusreports')
  return response.data
})

export const getAllJobs = createAsyncThunk('monitoring/getAllJobs', async (role) => {
  const response = await axios.get(`/jobs/${role}`)
  return response.data
})

export const getDivision = createAsyncThunk('reports/getDivision', async (id) => {
  const response = await axios.get(`/jobs/division/${id}`)
  return response.data
})

export const getAllDivisions = createAsyncThunk('monitoring/getAllDivisions', async () => {
  const response = await axios.get('/divisions')
  return response.data
})

export const getAllTags = createAsyncThunk('monitoring/getAllTags', async () => {
  const response = await axios.get('/tags')
  return response.data
})

export const getAllClassifications = createAsyncThunk('monitoring/getAllClassifications', async () => {
  const response = await axios.get('/classifications')
  return response.data
})

export const addReport = createAsyncThunk(
    'monitoring/addReport',
    async (report, { dispatch }) => {
        const response = await axios.post('/reports', report)
        await dispatch(getAll('todo'))
        return response.data
    }
)

export const addDisposisi = createAsyncThunk(
  'monitoring/addDisposisi',
  async (disposisi, { dispatch }) => {
      const response = await axios.post('/instructions', disposisi)
      await dispatch(getAll('todo'))
      return response.data
  }
)

export const updateData = createAsyncThunk(
    'monitoring/updateData',
    async ({id, report}, { dispatch }) => {
        const response = await axios.patch(`/reports/${id}`, report)
        await dispatch(getAll('todo'))
        return response.data
    }
)

export const deleteData = createAsyncThunk(
    'monitoring/deleteData',
    async ({ id }, { dispatch }) => {
        const response = await axios.delete(`/reports/${id}`)
        await dispatch(getAll('todo'))
        return response.data
    })

export const dataSlice = createSlice({
    name: 'monitoring',
    initialState: {
        results: [],
        result: null,
        selected: null,
        division_name: null,
        statusOptions: [],
        divisionOptions: [],
        tagsOptions: [],
        classificationOptions: [],
        jobOptions: [],
        isLoading: false
    },
    reducers: {
      selectData: (state, action) => {
            if (action.payload === null) {
                state.selected = null
            } else {
                state.selected = action.payload
            }
        }
    },
    extraReducers: (builder) => {
      builder
        .addCase(getAll.pending, (state) => {
          state.isLoading = true
        })
        .addCase(getAll.fulfilled, (state, action)  => {
          state.isLoading = false
          state.results = action.payload
        })
        .addCase(addReport.pending, (state) => {
          state.isLoading = true
        })
        .addCase(addReport.rejected, (state) => {
          state.isLoading = false
          MySwal.fire({
            title: 'Failed!',
            text: 'Mengirim laporan gagal',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          })
        })
        .addCase(addReport.fulfilled, (state) => {
          state.isLoading = false
          MySwal.fire({
            title: 'Success!',
            text: 'Mengirim laporan berhasil',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          })
        })
        .addCase(addDisposisi.rejected, () => {
          MySwal.fire({
            title: 'Failed!',
            text: 'Membuat disposisi gagal',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          })
        })
        .addCase(addDisposisi.fulfilled, () => {
          MySwal.fire({
            title: 'Success!',
            text: 'Membuat disposisi berhasil',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          })
        })
        .addCase(updateData.pending, (state) => {
          state.isLoading = true
        })
        .addCase(updateData.rejected, (state) => {
          state.isLoading = false
          MySwal.fire({
            title: 'Failed!',
            text: 'Ubah data gagal',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          })
        })
        .addCase(updateData.fulfilled, (state) => {
          state.isLoading = false
          MySwal.fire({
            title: 'Success!',
            text: 'Ubah data berhasil',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          })
        })
        .addCase(deleteData.rejected, () => {
          MySwal.fire({
            title: 'Failed!',
            text: 'Hapus data gagal',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          })
        })
        .addCase(deleteData.fulfilled, () => {
          MySwal.fire({
            title: 'Success!',
            text: 'Hapus data berhasil',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          })
        })
        .addCase(getAllStatus.pending, (state) => {
          state.isLoading = true
        })
        .addCase(getAllStatus.fulfilled, (state, action)  => {
          state.isLoading = false
          state.statusOptions = []
          action.payload.map(item => {
            state.statusOptions.push({ value: item.id, label: item.name })
          })
        })
        .addCase(getAllJobs.pending, (state) => {
          state.isLoading = true
        })
        .addCase(getAllJobs.fulfilled, (state, action)  => {
          state.isLoading = false
          state.jobOptions = []
          action.payload.map(item => {
            state.jobOptions.push({ value: item.id, label: item.name })
          })
        })
        .addCase(getAllDivisions.pending, (state) => {
          state.isLoading = true
        })
        .addCase(getAllDivisions.fulfilled, (state, action)  => {
          state.isLoading = false
          state.divisionOptions = []
          action.payload.map(item => {
            state.divisionOptions.push({ value: item.id, label: item.name })
          })
        })
        .addCase(getAllTags.pending, (state) => {
          state.isLoading = true
        })
        .addCase(getAllTags.fulfilled, (state, action)  => {
          state.isLoading = false
          state.tagsOptions = []
          action.payload.map(item => {
            state.tagsOptions.push({ value: item.id, label: item.name })
          })
        })
        .addCase(getAllClassifications.pending, (state) => {
          state.isLoading = true
        })
        .addCase(getAllClassifications.fulfilled, (state, action)  => {
          state.isLoading = false
          state.classificationOptions = []
          action.payload.map(item => {
            state.classificationOptions.push({ value: item.id, label: item.title })
          })
        })
        .addCase(getDivision.pending, (state) => {
          state.isLoading = true
        })
        .addCase(getDivision.fulfilled, (state, action)  => {
          state.isLoading = false
          state.division_name = action.payload.division
        })
    }
})
export const { selectData } = dataSlice.actions
export default dataSlice.reducer
