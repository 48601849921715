// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

// ** Axios Imports
import axios from 'axios'

export const getAllOutgoing = createAsyncThunk('disposisi/getAllOutgoing', async () => {
  const response = await axios.get('/instructions/outgoing')
  return response.data
})

export const getAllIncoming = createAsyncThunk('disposisi/getAllIncoming', async () => {
  const response = await axios.get('/instructions/incoming')
  return response.data
})

export const getDisposisi = createAsyncThunk('disposisi/getDisposisi', async (id) => {
  const response = await axios.get(`/instructions/${id}`)
  return response.data
})

export const getAllTags = createAsyncThunk('disposisi/getAllTags', async () => {
  const response = await axios.get('/tags')
  return response.data
})

export const getFile = createAsyncThunk('disposisi/getFile', async (file) => {
  const response = await axios.post(`/reports/file`, file, { responseType: 'blob' })
  const fileData = response.data
  // Buat objek URL untuk file
  const fileUrl = URL.createObjectURL(fileData)
  return fileUrl
})

export const deleteData = createAsyncThunk(
  'disposisi/deleteData',
  async ({ id }, { dispatch }) => {
    const response = await axios.delete(`/instructions/${id}`)
    await dispatch(getAllOutgoing())
    return response.data
  })

export const getAllStatus = createAsyncThunk('disposisi/getAllStatus', async () => {
  const response = await axios.get('/statusreports')
  return response.data
})

export const updateData = createAsyncThunk(
  'disposisi/updateData',
  async ({id, disposisi}, { dispatch }) => {
      const response = await axios.patch(`/instructions/${id}`, disposisi)
      await dispatch(getAllIncoming())
      return response.data
  }
)

export const dataSlice = createSlice({
  name: 'disposisi',
  initialState: {
    outgoing: [],
    incoming: [],
    result: {},
    histories: [],
    statusOptions: [],
    tagsOptions: [],
    file: null,
    selected: null,
    isLoading: false
  },
  reducers: {
    selectData: (state, action) => {
      if (action.payload === null) {
        state.selected = null
      } else {
        state.selected = action.payload
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllOutgoing.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getAllOutgoing.fulfilled, (state, action) => {
        state.isLoading = false
        state.outgoing = action.payload
      })
      .addCase(getAllIncoming.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getAllIncoming.fulfilled, (state, action) => {
        state.isLoading = false
        state.incoming = action.payload
      })
      .addCase(getFile.pending, (state) => {
        state.file = null
        state.isLoading = true
      })
      .addCase(getFile.fulfilled, (state, action) => {
        state.isLoading = false
        state.file = action.payload
      })
      .addCase(getDisposisi.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getDisposisi.fulfilled, (state, action) => {
        state.isLoading = false
        state.result = action.payload.data
        state.histories = action.payload.histories
      })
      .addCase(deleteData.rejected, () => {
        MySwal.fire({
          title: 'Failed!',
          text: 'Hapus data gagal',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary'
          },
          buttonsStyling: false
        })
      })
      .addCase(updateData.rejected, () => {
        MySwal.fire({
          title: 'Failed!',
          text: 'Ubah data gagal',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary'
          },
          buttonsStyling: false
        })
      })
      .addCase(updateData.fulfilled, () => {
        MySwal.fire({
          title: 'Success!',
          text: 'Ubah data berhasil',
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary'
          },
          buttonsStyling: false
        })
      })
      .addCase(deleteData.fulfilled, () => {
        MySwal.fire({
          title: 'Success!',
          text: 'Hapus data berhasil',
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary'
          },
          buttonsStyling: false
        })
      })
      .addCase(getAllStatus.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getAllStatus.fulfilled, (state, action) => {
        state.isLoading = false
        state.statusOptions = []
        action.payload.map(item => {
          state.statusOptions.push({ value: item.id, label: item.name })
        })
      })
      .addCase(getAllTags.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getAllTags.fulfilled, (state, action) => {
        state.isLoading = false
        state.tagsOptions = []
        action.payload.map(item => {
          state.tagsOptions.push({ value: item.id, label: item.name })
        })
      })
  }
})
export const { selectData } = dataSlice.actions
export default dataSlice.reducer
